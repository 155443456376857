.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  top: 21px !important;
  left: -17px !important;
}

.react-datepicker__triangle {
  transition: none !important;
  left: 50% !important;
}
